import { green, lightBlue, yellow } from '@mui/material/colors'
import { createTheme, PaletteColorOptions } from '@mui/material/styles'

// Access default theme
const defaultTheme = createTheme()

export const uCupRed = '#FF8484'
export const uCupBlue = '#66CFEC'

const uCupPrimary: PaletteColorOptions = {
  ...lightBlue,
  light: '#D4F3FF',
  main: uCupBlue,
  dark: '#3A669B',
}
const uCupSecondary: PaletteColorOptions = {
  ...yellow,
  main: '#FFE471',
}
const uCupSuccess: PaletteColorOptions = {
  ...green,
  main: '#88E283',
}
const uCupWarning: PaletteColorOptions = {
  ...yellow,
  main: '#FFAD61',
}
const uCupError: PaletteColorOptions = {
  ...yellow,
  main: uCupRed,
}

const theme = createTheme({
  shadows: [
    'none', // Shadow index 0 (default)
    // '0px 4px 10px rgba(0, 0, 0, 0.25)', // Shadow index 1
    '0px 2px 1px -1px rgba(0,0,0,0.2)', // Shadow index 1
    // '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)', // Shadow index 1
    defaultTheme.shadows[2],
    defaultTheme.shadows[3],
    defaultTheme.shadows[4],
    defaultTheme.shadows[5],
    defaultTheme.shadows[6],
    defaultTheme.shadows[7],
    defaultTheme.shadows[8],
    defaultTheme.shadows[9],
    defaultTheme.shadows[10],
    defaultTheme.shadows[11],
    defaultTheme.shadows[12],
    defaultTheme.shadows[13],
    defaultTheme.shadows[14],
    defaultTheme.shadows[15],
    defaultTheme.shadows[16],
    defaultTheme.shadows[17],
    defaultTheme.shadows[18],
    defaultTheme.shadows[19],
    defaultTheme.shadows[20],
    defaultTheme.shadows[21],
    defaultTheme.shadows[22],
    defaultTheme.shadows[23],
    defaultTheme.shadows[24],
  ],
  shape: {
    borderRadius: 10,
  },
  palette: {
    background: {
      default: '#F3F9FC',
    },
    primary: uCupPrimary,
    secondary: uCupSecondary,
    success: uCupSuccess,
    warning: uCupWarning,
    error: uCupError,
    grey: {
      A100: '#C4C4C4',
      '600': '#777777',
    },
    text: {
      secondary: 'rgba(18, 18, 18, 0.75)',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans TC',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    h4: {
      // fontWeight: 700,
    },
    h5: {
      // fontWeight: 700,
    },
    h6: {
      // fontWeight: 700,
    },
    body1: {
      // fontWeight: 700,
      letterSpacing: '0.05em',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        // disableElevation: true,
      },
      styleOverrides: {
        contained: {
          color: 'white',
        },
      },
    },
  },
})

export default theme
