/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query GetCarts {\n    cart(where: {}) {\n      id\n      cart_items {\n        cart_id\n        create_time\n        checked\n        id\n        note\n        price\n        product_id\n        quantity\n        product {\n          id\n          image_url\n          create_time\n          active\n          deadtime\n          price\n          product_info\n          product_name\n        }\n      }\n      total_amount\n      note\n      user_id\n    }\n  }\n": types.GetCartsDocument,
    "\n  query GetRentAggregate {\n    total_usage_aggregate: rent_aggregate(\n      where: { rent_type: { _eq: \"uCup\" } }\n    ) {\n      aggregate {\n        count\n      }\n    }\n    renting_num_aggregate: rent_aggregate(\n      where: { rent_type: { _eq: \"uCup\" }, _not: { returns: {} } }\n    ) {\n      nodes {\n        id\n        create_time\n        store {\n          id\n          store_name\n          image_url\n        }\n        cup_id\n        rent_type\n        returns {\n          id\n          create_time\n        }\n        provider\n        create_time\n        note\n      }\n      aggregate {\n        count\n      }\n    }\n  }\n": types.GetRentAggregateDocument,
    "\n  query GetProducts {\n    product(where: { active: { _eq: true }, price: { _neq: 0 } }) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      price\n    }\n  }\n": types.GetProductsDocument,
    "\n  query GetProductsWithPoints($_or: [product_bool_exp!] = {}) {\n    product(\n      where: { active: { _eq: true }, price: { _eq: 0 }, _or: $_or }\n      order_by: { display_order: asc }\n    ) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      store {\n        id\n        store_name\n        image_url\n      }\n    }\n  }\n": types.GetProductsWithPointsDocument,
    "\n  query GetProductOrders {\n    product_order(order_by: { create_time: desc }) {\n      id\n      payment_status\n      total_amount\n      create_time\n      product_order_items {\n        product {\n          product_name\n          image_url\n        }\n      }\n    }\n  }\n": types.GetProductOrdersDocument,
    "\n  query GetProduct($id: Int!) {\n    product_by_pk(id: $id) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      price\n      store {\n        id\n        store_name\n        image_url\n        products {\n          id\n          create_time\n          deadtime\n          display_order\n          image_url\n          inventories {\n            number\n          }\n          price\n          active\n          point\n          product_info\n          product_name\n          updated_time\n        }\n      }\n    }\n  }\n": types.GetProductDocument,
    "\n  query GetStore($id: Int!) {\n    store_by_pk(id: $id) {\n      id\n      store_name\n      image_url\n      store_type\n      website\n      address\n      phone\n      info\n      cup_nums {\n        id\n        clean_number\n      }\n      products(where: { active: { _eq: true } }) {\n        id\n        create_time\n        deadtime\n        display_order\n        image_url\n        inventories {\n          number\n        }\n        point\n        product_info\n        product_name\n        updated_time\n      }\n    }\n  }\n": types.GetStoreDocument,
    "\n  mutation MyMutation($id: String = \"\") {\n    update_user_by_pk(pk_columns: { id: $id }, _set: { terms_agreed: true }) {\n      terms_agreed\n    }\n  }\n": types.MyMutationDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCarts {\n    cart(where: {}) {\n      id\n      cart_items {\n        cart_id\n        create_time\n        checked\n        id\n        note\n        price\n        product_id\n        quantity\n        product {\n          id\n          image_url\n          create_time\n          active\n          deadtime\n          price\n          product_info\n          product_name\n        }\n      }\n      total_amount\n      note\n      user_id\n    }\n  }\n"): (typeof documents)["\n  query GetCarts {\n    cart(where: {}) {\n      id\n      cart_items {\n        cart_id\n        create_time\n        checked\n        id\n        note\n        price\n        product_id\n        quantity\n        product {\n          id\n          image_url\n          create_time\n          active\n          deadtime\n          price\n          product_info\n          product_name\n        }\n      }\n      total_amount\n      note\n      user_id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetRentAggregate {\n    total_usage_aggregate: rent_aggregate(\n      where: { rent_type: { _eq: \"uCup\" } }\n    ) {\n      aggregate {\n        count\n      }\n    }\n    renting_num_aggregate: rent_aggregate(\n      where: { rent_type: { _eq: \"uCup\" }, _not: { returns: {} } }\n    ) {\n      nodes {\n        id\n        create_time\n        store {\n          id\n          store_name\n          image_url\n        }\n        cup_id\n        rent_type\n        returns {\n          id\n          create_time\n        }\n        provider\n        create_time\n        note\n      }\n      aggregate {\n        count\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRentAggregate {\n    total_usage_aggregate: rent_aggregate(\n      where: { rent_type: { _eq: \"uCup\" } }\n    ) {\n      aggregate {\n        count\n      }\n    }\n    renting_num_aggregate: rent_aggregate(\n      where: { rent_type: { _eq: \"uCup\" }, _not: { returns: {} } }\n    ) {\n      nodes {\n        id\n        create_time\n        store {\n          id\n          store_name\n          image_url\n        }\n        cup_id\n        rent_type\n        returns {\n          id\n          create_time\n        }\n        provider\n        create_time\n        note\n      }\n      aggregate {\n        count\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetProducts {\n    product(where: { active: { _eq: true }, price: { _neq: 0 } }) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      price\n    }\n  }\n"): (typeof documents)["\n  query GetProducts {\n    product(where: { active: { _eq: true }, price: { _neq: 0 } }) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      price\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetProductsWithPoints($_or: [product_bool_exp!] = {}) {\n    product(\n      where: { active: { _eq: true }, price: { _eq: 0 }, _or: $_or }\n      order_by: { display_order: asc }\n    ) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      store {\n        id\n        store_name\n        image_url\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProductsWithPoints($_or: [product_bool_exp!] = {}) {\n    product(\n      where: { active: { _eq: true }, price: { _eq: 0 }, _or: $_or }\n      order_by: { display_order: asc }\n    ) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      store {\n        id\n        store_name\n        image_url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetProductOrders {\n    product_order(order_by: { create_time: desc }) {\n      id\n      payment_status\n      total_amount\n      create_time\n      product_order_items {\n        product {\n          product_name\n          image_url\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProductOrders {\n    product_order(order_by: { create_time: desc }) {\n      id\n      payment_status\n      total_amount\n      create_time\n      product_order_items {\n        product {\n          product_name\n          image_url\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetProduct($id: Int!) {\n    product_by_pk(id: $id) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      price\n      store {\n        id\n        store_name\n        image_url\n        products {\n          id\n          create_time\n          deadtime\n          display_order\n          image_url\n          inventories {\n            number\n          }\n          price\n          active\n          point\n          product_info\n          product_name\n          updated_time\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProduct($id: Int!) {\n    product_by_pk(id: $id) {\n      id\n      create_time\n      deadtime\n      display_order\n      image_url\n      inventories {\n        number\n      }\n      point\n      product_info\n      product_name\n      updated_time\n      price\n      store {\n        id\n        store_name\n        image_url\n        products {\n          id\n          create_time\n          deadtime\n          display_order\n          image_url\n          inventories {\n            number\n          }\n          price\n          active\n          point\n          product_info\n          product_name\n          updated_time\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStore($id: Int!) {\n    store_by_pk(id: $id) {\n      id\n      store_name\n      image_url\n      store_type\n      website\n      address\n      phone\n      info\n      cup_nums {\n        id\n        clean_number\n      }\n      products(where: { active: { _eq: true } }) {\n        id\n        create_time\n        deadtime\n        display_order\n        image_url\n        inventories {\n          number\n        }\n        point\n        product_info\n        product_name\n        updated_time\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStore($id: Int!) {\n    store_by_pk(id: $id) {\n      id\n      store_name\n      image_url\n      store_type\n      website\n      address\n      phone\n      info\n      cup_nums {\n        id\n        clean_number\n      }\n      products(where: { active: { _eq: true } }) {\n        id\n        create_time\n        deadtime\n        display_order\n        image_url\n        inventories {\n          number\n        }\n        point\n        product_info\n        product_name\n        updated_time\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MyMutation($id: String = \"\") {\n    update_user_by_pk(pk_columns: { id: $id }, _set: { terms_agreed: true }) {\n      terms_agreed\n    }\n  }\n"): (typeof documents)["\n  mutation MyMutation($id: String = \"\") {\n    update_user_by_pk(pk_columns: { id: $id }, _set: { terms_agreed: true }) {\n      terms_agreed\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;