import { lazy, Suspense } from 'react'
import { QueryClientProvider } from 'react-query'
import {
  BrowserRouter as Router,
  Route,
  useOutlet,
  Routes,
} from 'react-router-dom'
import AuthProvider from 'src/shared/auth/context/authUser'
import myApolloClientWithAuth from 'src/shared/dataProvider/apollo'
import queryClient from 'src/shared/dataProvider/client'
import { useQueryParams } from 'src/shared/router-utils'
import { SnackbarProvider, useSnackbar } from 'src/shared/snackbar/context'
import Snackbar from 'src/shared/snackbar/Snackbar'

import { ApolloProvider } from '@apollo/client'
import { builder } from '@builder.io/react'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { ErrorBoundary } from '@sentry/react'

import LabelBottomNavigation from './components/LabelBottomNavigation'
import Loading from './components/Loading/Loading'
import RentOrReturn from './pages/RentOrReturn'
import theme from './theme'
import { Lost } from './pages/lost'
import { LiffProvider, useLiff } from './shared/liff'
import { SimpleError, UnknownError } from './shared/error/components'
import { parseError } from './utils/utils'
import ScrollToTop from './components/ScrollToTop'

const ProductOrderRecord = lazy(() => import('./pages/product-order'))
const StoreShow = lazy(() => import('./pages/stores/show'))
const ProductShow = lazy(() => import('./pages/products/show'))
const Index = lazy(() => import('./pages/Index/Index'))
const Points = lazy(() => import('./pages/Points/Points'))
// const BindNtuLine = lazy(() => import('./pages/Bind/BindNtuLine'))
const BindSMS = lazy(() => import('./pages/Bind/BindSms'))
const BindEmail = lazy(() => import('./pages/Bind/BindEmail'))
const Bind = lazy(() => import('./pages/Bind/Bind'))
const Stores = lazy(() => import('./pages/stores/list'))
const Leaderboards = lazy(() => import('./pages/Leaderboards'))
const Upgrade = lazy(() => import('./pages/upgrade'))
const UpgradeDetail = lazy(() => import('./pages/upgrade/detail'))
const UpgradeResult = lazy(() => import('./pages/upgrade/result'))
const Custos = lazy(() => import('./pages/custos'))
const ECommerce = lazy(() => import('./pages/ECommerce'))
const Cart = lazy(() => import('./pages/Cart'))
const Checkout = lazy(() => import('./pages/checkout'))
const ThankYou = lazy(() => import('./pages/checkout/ThankYou'))
const OrderDetails = lazy(() => import('./pages/product-order/show'))
const MyPage = lazy(() => import('./pages/my-page'))
const Rent = lazy(() => import('./pages/rents'))
const Bonuses = lazy(() => import('./pages/bonuses'))
const BonusesCreate = lazy(() => import('./pages/bonuses/create'))

builder.init(process.env.REACT_APP_BUILDER_API_KEY ?? '')

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloProvider client={myApolloClientWithAuth}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider>
              <Router>
                {/* <MyAppBar profile={profile} /> */}
                {/* <DenseAppBar /> */}
                <ScrollToTop />
                <Container
                  sx={{
                    paddingTop: 2,
                    paddingBottom: 8,
                    minHeight: '90vh',
                  }}
                  maxWidth="sm"
                >
                  <Suspense fallback={<Loading debugInfo="suspense" />}>
                    <ErrorBoundary
                      fallback={({ error, componentStack, resetError }) => (
                        <>
                          <UnknownError
                            detail={error.toString()}
                            onRetry={() => {
                              resetError()
                            }}
                          />
                        </>
                      )}
                    >
                      <Routes>
                        <Route path="/stores" element={<Stores />} />
                        <Route path="/" element={<LiffAuthLayout />}>
                          <Route path="own" element={<Index />} />
                          <Route path="points" element={<Points />} />
                          <Route path="custos" element={<Custos />} />
                          <Route path="stores/:id" element={<StoreShow />} />
                          <Route
                            path="products/:id"
                            element={<ProductShow />}
                          />
                          {/* old */}
                          <Route path="records" element={<MyPage />} />
                          {/* new */}
                          <Route path="my-page" element={<MyPage />} />
                          <Route
                            path="product-orders"
                            element={<ProductOrderRecord />}
                          />
                          <Route path="lost" element={<Lost />} />
                          <Route path="rents" element={<Rent />} />
                          <Route path="bonuses" element={<Bonuses />} />
                          <Route
                            path="bonuses/create"
                            element={<BonusesCreate />}
                          />
                          <Route
                            path="leaderboards"
                            element={<Leaderboards />}
                          />
                          <Route path="ecommerce" element={<ECommerce />} />
                          {/* <Route
                            path="login/bind/:studentId"
                            element={<BindNtuLine />}
                          ></Route> */}
                          <Route path="bind">
                            <Route path="" element={<Bind />}></Route>
                            <Route path="sms" element={<BindSMS />}></Route>
                            <Route path="email" element={<BindEmail />}></Route>
                          </Route>
                          <Route
                            path="upgrade/result"
                            element={<UpgradeResult />}
                          />
                          <Route
                            path="upgrade/:subscriptionType/detail"
                            element={<UpgradeDetail />}
                          />
                          <Route path="upgrade" element={<Upgrade />} />
                          {/* E-Commerce */}
                          <Route path="cart" element={<Cart />} />
                          <Route path="checkout" element={<Checkout />} />
                          <Route
                            path="orders"
                            element={<ProductOrderRecord />}
                          />
                          <Route
                            path="orders/:orderId"
                            element={<OrderDetails />}
                          />{' '}
                          <Route
                            path="checkout/thankyou"
                            element={<ThankYou />}
                          />
                          <Route path="" element={<Home />} />
                        </Route>
                      </Routes>
                    </ErrorBoundary>
                  </Suspense>
                </Container>
                <SnackbarSection />
                <LabelBottomNavigation />
              </Router>
            </SnackbarProvider>
          </QueryClientProvider>
        </ApolloProvider>

        {/* {process.env.NODE_ENV === 'development' && <DebugInfo />} */}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

function AuthLayout({ children }) {
  const { liff, loading, error } = useLiff()
  if (loading) {
    return <Loading debugInfo="liff" />
  }
  if (error) {
    return <SimpleError msg={parseError(error)} />
  }
  if (!liff) {
    return <SimpleError msg="Liff not found" />
  }

  return <AuthProvider liff={liff}>{children}</AuthProvider>
}

function LiffAuthLayout() {
  const outlet = useOutlet()
  return (
    <LiffProvider>
      <AuthLayout>{outlet}</AuthLayout>
    </LiffProvider>
  )
}

function SnackbarSection() {
  const { snackbar, setSnackbar } = useSnackbar()
  return (
    <Snackbar
      open={snackbar.open}
      severity={snackbar.severity}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
      timeout={snackbar?.timeout || 5000}
    >
      {snackbar.message}
    </Snackbar>
  )
}
function Home() {
  const query = useQueryParams()
  const loggedIn = query.get('loggedIn')
  const redirecting = query.get('liff.state')
  const storeId = query.get('storeId')
  if (loggedIn) {
    return <LoggedIn />
  } else if (redirecting) {
    return <Loading debugInfo="redirect" />
  } else {
    if (storeId) return <RentOrReturn />
    else return <Index />
  }
}

function LoggedIn() {
  return (
    <div>
      <h2>歡迎使用uCup，請點擊正下方按鈕開始使用。</h2>
      <h2>
        若綁定時，因登入後跳轉至此頁，請再點擊一次綁定連結，或將連結貼到瀏覽器，以完成綁定。
      </h2>
    </div>
  )
}
